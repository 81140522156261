import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import { OtpInput } from "react-native-otp-entry";

import { Page } from "../../../components";
import { AuthStackParamList } from "../../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../../stores";
import {
  Button,
  Colors,
  Margins,
  Radius,
  Text,
  TextInput,
  Title,
} from "../../../storybook";

const styles = StyleSheet.create({
  content: {
    padding: Margins.large,
  },
  title: {
    marginBottom: Margins.small,
  },
  text: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type EmailNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AuthEmailConfirm"
>;
type EmailRouteProp = RouteProp<AuthStackParamList, "AuthEmailConfirm">;

interface Props {
  navigation: EmailNavigationProp;
  route: EmailRouteProp;
}
const EmailConfirm = (props: Props) => {
  const { route } = props;
  const { next, spotId } = route.params || {};
  const [otpCode, setOtpCode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [timer, setTimer] = useState(0);

  const savedEmail = window.localStorage.getItem("email");
  const store = useStore();
  const { auth } = store;
  const linkTo = useLinkTo();
  const { t } = useTranslation();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  useEffect(() => {
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const verifyCode = async (code: string) => {
    if (!email) {
      setError(t("Email is required"));
      return;
    }

    setLoading(true);
    try {
      await auth.verifyOtp({
        email,
        otp: code,
      });

      if (Platform.OS === "web") {
        window.location.href = next || "/";
      } else {
        linkTo(next || "/");
      }
    } catch (error) {
      console.error("Error: ", error);
      setError(t("Invalid verification code. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    if (!email) {
      setError(t("Email is required"));
      return;
    }

    setLoadingResend(true);
    try {
      await auth.requestOtp({ email });
      setTimer(60); // Set a 60-second timer before allowing resend
    } catch (e) {
      console.error(e);
      setError(t("Failed to send verification code. Please try again."));
    } finally {
      setLoadingResend(false);
    }
  };

  return (
    <Page spot={typeof spot === "string" ? undefined : spot}>
      <View style={{ width: "100%", marginTop: Margins.regular }}>
        <Title level={1} style={styles.text}>
          {error ? (
            <Trans>Verification Failed</Trans>
          ) : (
            <Trans>Enter Your Verification Code</Trans>
          )}
        </Title>
        {typeof error === "string" ? (
          <>
            <Text style={{ color: Colors.red, marginBottom: Margins.small }}>
              {error}
            </Text>
          </>
        ) : error ? (
          <>
            <Text>
              <Trans>
                The code could not be verified. Please double-check and try
                again.
              </Trans>
            </Text>
            <Button
              style={styles.button}
              title={t("Try again")}
              onPress={() => setError(false)}
            />
          </>
        ) : (
          <>
            <Text style={styles.text}>
              {email && email.length > 0
                ? t(
                    "Enter the 6-digit code sent to $1 to complete verification."
                  ).replace("$1", email)
                : t(
                    "Enter the 6-digit code sent to your email to complete verification."
                  )}
            </Text>
            {!savedEmail && (
              <TextInput
                label={t("Email")}
                onChangeText={setEmail}
                value={email}
                style={{ marginBottom: Margins.regular }}
              />
            )}
            <OtpInput
              numberOfDigits={6}
              onTextChange={(text) => setOtpCode(text)}
              onFilled={(text) => verifyCode(text)}
              autoFocus
              theme={{
                containerStyle: { maxWidth: 340, alignSelf: "center" },
                pinCodeContainerStyle: { backgroundColor: Colors.white },
                focusedPinCodeContainerStyle: { borderColor: Colors.forest },
                focusStickStyle: { backgroundColor: Colors.forest },
              }}
              textInputProps={{ caretHidden: true }}
            />
            <Button
              style={styles.button}
              title={t("Verify Code")}
              onPress={() => verifyCode(otpCode)}
              loading={loading}
              disabled={otpCode.length !== 6 || loading || !email}
            />
            <Button
              style={styles.button}
              title={
                timer > 0
                  ? t("Resend Code ($1s)", { $1: timer })
                  : t("Resend Code")
              }
              centre="rotateCw"
              type="transparent"
              onPress={resendOtp}
              loading={loadingResend}
              disabled={loadingResend || timer > 0 || !email}
            />
          </>
        )}
      </View>
    </Page>
  );
};

export default withAuthentication(observer(EmailConfirm));
